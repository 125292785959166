<template>
  <div class="search-user flex flex-col">
    <div
      class="flex flex-col items-center self-center w-11/12 mt-8 bg-yellow p-4"
    >
      <h2 class="font-bold text-2xl">Buscar usuario por:</h2>
      <div class="mt-2 flex items-center">
        <div class="flex items-center mr-4">
          <input
            type="radio"
            class="mr-2"
            v-model="type"
            value="email"
            id="email"
          />
          <label for="email">Email</label>
        </div>
        <div class="flex items-center mr-4">
          <input type="radio" class="mr-2" v-model="type" value="id" id="id" />
          <label for="id">Id</label>
        </div>
      </div>
      <form @submit.prevent="onSubmit">
        <input
          type="text"
          v-model="search"
          class="py-2 px-4 rounded-lg my-4 mr-4 bg-white"
          required
        />
        <button class="bg-white text-yellow uppercase rounded-lg p-2 font-bold">
          Buscar
        </button>
      </form>

      <div class="flex flex-col items-start w-4/5" v-if="user">
        <div class="grid grid-cols-2 w-full items-center justify-items-center">
          <p class="mb-0">
            <span class="font-bold">Nombre: </span>{{ user.name }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Teléfono: </span>{{ user.phone }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Ciudad: </span>{{ user.city }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Compañia: </span
            >{{ company?.name ?? "EMPRESA ELIMINADA" }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Rol: </span
            >{{ user.role === "WORKER" ? "Dependiente" : "Dueño" }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Email: </span>{{ user.email }}
          </p>
          <p class="mb-0">
            <span class="font-bold">Registrado: </span
            >{{ new Date(user.createdAt).toLocaleDateString() }}
          </p>
          <p class="mb-0">
            <span class="font-bold">{{ user.vip ? "VIP" : "NO VIP" }}</span>
          </p>
        </div>
        <div class="w-full flex items-center justify-center gap-8">
          <button
            class="bg-white text-yellow uppercase rounded-lg p-2 font-bold mt-4"
            @click="$router.push(`/user/${user.uid}`)"
          >
            Ver detalles
          </button>
          <button
            class="bg-red-700 text-white uppercase rounded-lg p-2 font-bold mt-4"
            @click="() => (confirmDelete = true)"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="confirmDelete"
      class="absolute top-0 left-0 bg-black bg-opacity-60 w-full min-h-screen flex items-center justify-center"
    >
      <div
        class="bg-white w-1/2 border-2 border-red-700 rounded-3xl py-10 px-10 relative"
      >
        <div
          class="absolute -top-10 -right-10 bg-white py-2 px-4 rounded-full font-bold cursor-pointer"
          @click="() => (confirmDelete = false)"
        >
          X
        </div>
        <p class="text-4xl text-red-700 font-bold">¿Está seguro de eliminar?</p>
        <p class="text-2xl font-bold mt-10">
          Nombre:
          <span class="text-2xl font-light text-red-700 uppercase">{{
            user.name
          }}</span>
        </p>
        <p class="text-2xl font-bold">
          Email:
          <span class="text-2xl font-light text-red-700">{{ user.email }}</span>
        </p>
        <p class="text-2xl font-bold">
          Compañía:
          <span class="text-base font-light break-words text-red-700">{{
            company?.name ?? "EMPRESA ELIMINADA"
          }}</span>
        </p>
        <p class="text-2xl font-bold">
          Tracksales:
          <span class="text-base font-light text-red-700">{{
            user.companyId
          }}</span>
        </p>
        <div class="w-full flex items-center justify-around gap-7">
          <button
            class="text-xl text-white bg-red-700 font-bold px-4 py-2 rounded-xl"
            @click="confirmDeleteUser(user.uid, user.companyId)"
          >
            Eliminar
          </button>
          <button
            class="text-xl text-white bg-yellow font-bold px-4 py-2 rounded-xl"
            @click="() => (confirmDelete = false)"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>

    <Loader :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "../../components/Loader.vue";
export default {
  components: { Loader },
  data() {
    return {
      isLoading: false,
      type: "",
      search: "",
      user: null,
      company: null,
      confirmDelete: false,
    };
  },
  methods: {
    ...mapActions(["user/searchUser", "user/deleteUser", "user/searchCompany"]),

    async onSubmit() {
      this.isLoading = true;
      const res = await this["user/searchUser"]({
        searchTerm: this.type,
        search: this.search,
      });
      if (res.success) {
        this.user = res.user;
        const companyData = await this["user/searchCompany"](
          res.user.companyId
        );
        this.company = companyData;
      }
      this.search = "";
      this.type = "";
      this.isLoading = false;
    },
    async confirmDeleteUser(uid, companyId) {
      this.isLoading = true;
      const res = await this["user/deleteUser"]({ uid, companyId });

      if (res.success) {
        this.confirmDelete = false;
        this.user = null;
        this.isLoading = false;
        alert(res.message);
      } else {
        alert(res.message);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-user {
  font-family: "Nunito";
}
</style>
